<template>
  <li class="basic-feature__wrapper">
    <Icon
      :name="
        isHighContrastModeActive
          ? 'tariff_features--check-white'
          : 'tariff_features--check-dark'
      "
      class="feature-checkmark__image"
      :height="$breakpoint.upToTablet() ? '30' : '58'"
    />

    <div class="feature-text__wrapper">
      <h3 class="subtitle feature-heading">
        {{ title }}
      </h3>

      <slot />
    </div>
  </li>
</template>

<script>
import { DARK } from 'src/components/common/settings/themes.js'
import { mapState } from 'vuex'
import Icon from 'src/components/common/icon/icon.vue'

export default {
  name: 'TariffFeature',
  components: { Icon },
  props: {
    title: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapState({
      theme: state => state.settings.theme
    }),
    isHighContrastModeActive() {
      return this.theme === DARK
    }
  }
}
</script>
<style lang="scss" scoped>
.basic-feature__wrapper {
  position: relative;
  background-color: var(--background-primary);
  margin: 5px 2px;
  padding: 15px 15px 25px 20px;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  border-width: 2px;
  border-style: solid;
  border-color: $input-border-color;

  :deep(p) {
    padding-bottom: 10px;
    line-height: 1.3;
  }

  @include breakpoint($up-to-tablet) {
    flex-direction: row;
    padding-bottom: 7px;
  }
}

.feature-text__wrapper {
  display: flex;
  flex-direction: column;
  padding-top: 8px;
  padding-left: 2px;

  @include breakpoint($up-to-tablet) {
    padding-left: 0;
  }
}

.feature-heading {
  line-height: 1;
  margin-bottom: 7px;
}

.feature-checkmark__image {
  position: absolute;
  bottom: -30px;
  align-self: center;

  @include breakpoint($up-to-tablet) {
    position: relative;
    bottom: 0;
    align-self: start;
    padding-right: 10px;
    height: 40px;
    top: 2px;
    left: -3px;
  }
}
</style>
