<template>
  <div class="basic-tarifffeatures">
    <h2 class="title">Bei allen FONIC Smart Tarifen inklusive:</h2>

    <ul class="basic-tarifffeatures__wrapper">
      <Tarifffeature title="100% Prepaid">
        <p class="slim">Ohne Vertragsbindung und ohne Mindestumsatz</p>
      </Tarifffeature>
      <Tarifffeature title="Ausgezeichnet">
        <p class="slim">
          <span v-if="$brand.isFonic()">{{ $brand.name }} ist durch </span>
          <span v-else>Durch </span>
          zahlreiche Testurteile ausgezeichnet
        </p>
      </Tarifffeature>
      <Tarifffeature title="EU-Roaming inklusive">
        <p class="slim">
          Auch im
          <router-link :to="'/prepaid-tarife/prepaid-ausland'"
            >Ausland</router-link
          >
          SMSen, Telefonieren und Surfen
        </p>
      </Tarifffeature>
      <Tarifffeature title="5G">
        <p class="slim">
          Highspeed mit bis zu 50 MBit/s im Netz von Telefónica unterwegs
        </p>
      </Tarifffeature>
    </ul>
  </div>
</template>

<script>
import Tarifffeature from './tarif_feature.vue'

export default {
  name: 'PortalBasicTarifffeatures',
  components: {
    Tarifffeature
  }
}
</script>
<style lang="scss" scoped>
.basic-tarifffeatures {
  .title {
    padding-bottom: 20px;
  }
}

.basic-tarifffeatures__wrapper {
  display: grid;
  gap: 30px 25px;
  grid-template-columns: auto auto auto auto;
  grid-auto-rows: 1fr;
  padding: 0;

  @include breakpoint($up-to-three-columns) {
    grid-template-columns: 48% 48%;
  }

  @include breakpoint($up-to-tablet) {
    grid-template-columns: auto;
    grid-auto-rows: auto;
    gap: 10px;
  }
}
</style>
